import {
  BargeProfile,
  BaseUser,
  EmailSetting,
  FtpSetting,
  OriginDestinationPair,
  OriginDestinationTypes,
  TowlineProfile,
  User,
  UserTypeEnum,
} from '@/app/core/models/user.model';

export const buildUser = (user: Partial<User> = {}): User => {
  return {
    $id: '1',
    USERNAME: 'APOLO@CLOUDX.COM',
    CUST_NAME: 'CloudX',
    CUSTNUM: '00000',
    EMAIL: null,
    ENHANCED_REALITY_ACTIVE: 0,
    INTERNAL_USER: 0,
    INTERNET_ADMIN: 0,
    INTRANET_ADMIN: 0,
    LAST_LOGON: '0001-01-01T00:00:00Z',
    PASSWORD_CHANGED: 0,
    PERSON_DESCRIPTION: null,
    PERSON_FIRST: 'Agustin',
    PERSON_LAST: 'Polo',
    PHONE: null,
    SALESMGR: 'Nate McDonald',
    SALESOFFICE: null,
    TOWLINE_ACTIVE: -1,
    UNLOAD_ACTIVE: 0,
    USERAPP_ACTIVE: 0,
    DEFAULT_FILTER_ID: 7760,
    SEE_DEMURRAGE: -1,
    LOGS_ACTIVE: null,
    ...user,
  };
};

export const baseUsers: BaseUser[] = [
  {
    $id: '1',
    CUST_NAME: 'UKT CHICAGO',
    USER_NAME: 'A.BASHARIMOVA@GMAIL.COM',
    USERNAME_KEY: 'A.BASHARIMOVA@GMAIL.COM',
    USER_DISPLAY: 'Basharimova, Alena',
  },
  {
    $id: '2',
    CUST_NAME: 'UKT Chicago',
    USER_NAME: 'A.BASHARIMOVA@URALKALI-TRADING.COM',
    USERNAME_KEY: 'A.BASHARIMOVA@URALKALI-TRADING.COM',
    USER_DISPLAY: 'Basharimova, Alena',
  },
  {
    $id: '3',
    CUST_NAME: 'ELLIS PROCESSING & MATERIAL HANDLING',
    USER_NAME: 'A.HOHN@EPMH.NET',
    USERNAME_KEY: 'A.HOHN@EPMH.NET',
    USER_DISPLAY: 'Hohn, Adrienne',
  },
  {
    $id: '4',
    CUST_NAME: 'Mid River Terminal',
    USER_NAME: 'A.HOHN@MID-RIVERTERMINAL.COM',
    USERNAME_KEY: 'A.HOHN@MID-RIVERTERMINAL.COM',
    USER_DISPLAY: 'Hohn, Adrienne',
  },
  {
    $id: '5',
    CUST_NAME: 'Middle River Marine',
    USER_NAME: 'AARONHALCOMB@OZINGA.COM',
    USERNAME_KEY: 'AARONHALCOMB@OZINGA.COM',
    USER_DISPLAY: 'Halcomb, Aaron',
  },
  {
    $id: '6',
    CUST_NAME: 'MID-SHIP LOGISTICS',
    USER_NAME: 'AAURIEMMA@MIDSHIPLOGISTICS.COM',
    USERNAME_KEY: 'AAURIEMMA@MIDSHIPLOGISTICS.COM',
    USER_DISPLAY: 'AURIEMMA, ANDREW',
  },
  {
    $id: '7',
    CUST_NAME: 'Waterway Ag Inc.dba River Ag of Illinois',
    USER_NAME: 'AAYCOCK@WATERWAYAG.NET',
    USERNAME_KEY: 'AAYCOCK@WATERWAYAG.NET',
    USER_DISPLAY: 'Aycock, Allen',
  },
  {
    $id: '8',
    CUST_NAME: 'Americas Styrenics LLC',
    USER_NAME: 'ABALES@AMSTY.COM',
    USERNAME_KEY: 'ABALES@AMSTY.COM',
    USER_DISPLAY: 'Bales, Angela',
  },
  {
    $id: '9',
    CUST_NAME: 'A Block Dock (Access to Garick)',
    USER_NAME: 'ABLOCK4DEB@AOL.COM',
    USERNAME_KEY: 'ABLOCK4DEB@AOL.COM',
    USER_DISPLAY: 'Jackson, Debbie',
  },
  {
    $id: '419',
    CUST_NAME: null,
    USER_NAME: 'JACK.CONNER@MULZER.COM',
    USERNAME_KEY: 'JACK.CONNER@MULZER.COM',
    USER_DISPLAY: 'CONNER, JACK',
  },
  {
    $id: '420',
    CUST_NAME: 'Holcim',
    USER_NAME: 'JACK.HOLM@HOLCIM.COM',
    USERNAME_KEY: 'JACK.HOLM@HOLCIM.COM',
    USER_DISPLAY: 'Holm, Jack',
  },
  {
    $id: '421',
    CUST_NAME: 'Nucor Steel Gallatin',
    USER_NAME: 'JACK.MONTGOMERY@NUCOR.COM',
    USERNAME_KEY: 'JACK.MONTGOMERY@NUCOR.COM',
    USER_DISPLAY: 'Montgomery, Jack',
  },
  {
    $id: '422',
    CUST_NAME: 'Ottawa Barge Terminal Inc.',
    USER_NAME: 'JACK@OTTAWABARGE.COM',
    USERNAME_KEY: 'JACK@OTTAWABARGE.COM',
    USER_DISPLAY: 'Goodman, Jack',
  },
  {
    $id: '423',
    CUST_NAME: 'Nucor Steel Indiana',
    USER_NAME: 'JACKI.HORN@NUCOR.COM',
    USERNAME_KEY: 'JACKI.HORN@NUCOR.COM',
    USER_DISPLAY: 'Horn, Jacki',
  },
  {
    $id: '424',
    CUST_NAME: 'ALABAMA FARMERS COOP',
    USER_NAME: 'JACKS@ALAFARM.COM',
    USERNAME_KEY: 'JACKS@ALAFARM.COM',
    USER_DISPLAY: 'Segrest, Jack',
  },
  {
    $id: '425',
    CUST_NAME: 'EKP',
    USER_NAME: 'JACOB.BEVINS@EKPC.COOP',
    USERNAME_KEY: 'JACOB.BEVINS@EKPC.COOP',
    USER_DISPLAY: 'BEVINS, JACOB',
  },
  {
    $id: '426',
    CUST_NAME: 'Valero',
    USER_NAME: 'JACOB.CAMPBELL@VALERO.COM',
    USERNAME_KEY: 'JACOB.CAMPBELL@VALERO.COM',
    USER_DISPLAY: 'Campbell, Jacob',
  },
  {
    $id: '427',
    CUST_NAME: 'Valero Marketing and Supply Company',
    USER_NAME: 'JACQUELINE.CHARO@VALERO.COM',
    USERNAME_KEY: 'JACQUELINE.CHARO@VALERO.COM',
    USER_DISPLAY: 'Charo, Jacqueline',
  },
  {
    $id: '428',
    CUST_NAME: 'VALERO MARKETING AND SUPPLY CO.',
    USER_NAME: 'JAIME.QUINTANILLA2@VALERO.COM',
    USERNAME_KEY: 'JAIME.QUINTANILLA2@VALERO.COM',
    USER_DISPLAY: 'Quintanilla, Jaime',
  },
  {
    $id: '429',
    CUST_NAME: 'U.S. MINERALS',
    USER_NAME: 'JALLEN@US-MINERALS.COM',
    USERNAME_KEY: 'JALLEN@US-MINERALS.COM',
    USER_DISPLAY: 'Allen, Jason',
  },
  {
    $id: '430',
    CUST_NAME: 'Bayou Steel',
    USER_NAME: 'JAMES.GEMINN@BAYOUSTEEL.COM',
    USERNAME_KEY: 'JAMES.GEMINN@BAYOUSTEEL.COM',
    USER_DISPLAY: 'Geminn, James',
  },
  {
    $id: '431',
    CUST_NAME: 'CABOT CORPORATION',
    USER_NAME: 'JAMES.HARMS@CABOTCORP.COM',
    USERNAME_KEY: 'JAMES.HARMS@CABOTCORP.COM',
    USER_DISPLAY: 'Harms, James',
  },
];

export const users: User[] = [
  buildUser(),
  buildUser({
    $id: '5',
    CUST_NAME: null,
    USERNAME: 'ARIEL.ERVITI@INGRAMBARGE.COM',
    INTERNAL_USER: -1,
    PERSON_LAST: 'Erviti',
    PERSON_FIRST: 'Ariel',
    TOWLINE_ACTIVE: -1,
    PERSON_DESCRIPTION: null,
    UNLOAD_ACTIVE: 0,
    CUSTNUM: null,
    EMAIL: null,
    PASSWORD_CHANGED: 0,
    INTRANET_ADMIN: 0,
    LOGS_ACTIVE: 0,
    SALESMGR: null,
    SALESOFFICE: null,
    USERAPP_ACTIVE: 0,
    INTERNET_ADMIN: 0,
    PHONE: null,
    LAST_LOGON: '0001-01-01T00:00:00Z',
    ENHANCED_REALITY_ACTIVE: 0,
  }),
];

export const originDestinationsFetch: OriginDestinationPair[] = [
  {
    $id: '1',
    CUSTOM_ORIGINS_ID: 2148,
    RIVER: 'ALL',
    MILE: 1,
    ORIG_DEST: 'O' as OriginDestinationTypes,
    USERNAME: 'Ariel.Erviti@ingrambarge.com',
    UPDATED_BY: 'Ariel.Erviti@ingrambarge.com',
    UPDATED_ON: '2024-03-31T06:32:56.073Z',
    CREATED_BY: 'Ariel.Erviti@ingrambarge.com',
    CREATED_ON: '2024-03-31T06:32:56.073Z',
    RIVER_DISPLAY: 'ALL - 1.0 (Origin)',
  },
  {
    $id: '2',
    CUSTOM_ORIGINS_ID: 2150,
    RIVER: 'ALL',
    MILE: 1,
    ORIG_DEST: 'D' as OriginDestinationTypes,
    USERNAME: 'Ariel.Erviti@ingrambarge.com',
    UPDATED_BY: 'Ariel.Erviti@ingrambarge.com',
    UPDATED_ON: '2024-03-31T06:33:14.445Z',
    CREATED_BY: 'Ariel.Erviti@ingrambarge.com',
    CREATED_ON: '2024-03-31T06:33:14.445Z',
    RIVER_DISPLAY: 'ALL - 1.0 (Destination)',
  },
];

export const emailSettingsFetch: EmailSetting[] = [
  {
    $id: '1',
    email_queue: [],
    schedules: [],
    EMAIL_LISTS_ID: 502,
    USERNAME: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    EMAIL_LIST_NAME: 'a',
    EMAIL_TO: 'a@a.com',
    EMAIL_CC: null,
    EMAIL_BCC: null,
    UPDATED_BY: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    UPDATED_ON: '2024-02-23T23:08:58.102Z',
    CREATED_BY: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    CREATED_ON: '2024-02-23T23:08:58.102Z',
    LIST_ACTIVE: 'A',
  },
  {
    $id: '2',
    email_queue: [],
    schedules: [],
    EMAIL_LISTS_ID: 504,
    USERNAME: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    EMAIL_LIST_NAME: 'email',
    EMAIL_TO: 'asd@asd.com',
    EMAIL_CC: null,
    EMAIL_BCC: null,
    UPDATED_BY: 'Agustin.Polo@ingrambarge.com',
    UPDATED_ON: '2024-03-20T06:51:08.351Z',
    CREATED_BY: 'Agustin.Polo@ingrambarge.com',
    CREATED_ON: '2024-03-20T06:51:08.351Z',
    LIST_ACTIVE: 'A',
  },
];

export const ftpSettingsFetch: FtpSetting[] = [
  {
    $id: '1',
    ftp_queue: [],
    schedules: [],
    FTP_HOSTS_ID: 4098,
    USER_NAME: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    FTP_HOST: 'a',
    FTP_PORT: 1,
    FTP_USERNAME: 'a',
    FTP_PASSWORD: 'a',
    FTP_PATH: 'a',
    FTP_ERROR_EMAIL: 'test@test.com',
    USE_SSH: 1,
    PUBLIC_KEY: 'a',
    USE_SSL: null,
    SSL_IMPLICIT: null,
    SSL_CHECK_CRL: null,
    SSL_CHECK_PERIOD_FOR_TRUSTED: null,
    SSL_IGNORE_CA_KEY_USAGE: null,
    SSL_IGNORE_SYSTEM_TRUST: null,
    SSL_MANDATORY_CLR_CHECK: null,
    SSL_MANDATORY_OCSP_CHECK: null,
    SSL_USE_SYSTEM_STORAGES: null,
    SSL_VALIDATE_INVALID_CERT: null,
    SSL_PRIVATE_KEY: null,
    SSL_KEY_PASSWORD: null,
    UPDATED_BY: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    UPDATED_ON: '2024-02-23T23:06:32.102Z',
    CREATED_BY: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    CREATED_ON: '2024-02-23T23:06:32.102Z',
    HOST_ACTIVE: 'A',
  },
  {
    $id: '2',
    ftp_queue: [],
    schedules: [],
    FTP_HOSTS_ID: 4100,
    USER_NAME: 'AGUSTIN.POLO@INGRAMBARGE.COM',
    FTP_HOST: '192.168.1.1',
    FTP_PORT: 1234,
    FTP_USERNAME: 'username',
    FTP_PASSWORD: '1234',
    FTP_PATH: '/helo',
    FTP_ERROR_EMAIL: 'asd@asd.com',
    USE_SSH: 0,
    PUBLIC_KEY: '',
    USE_SSL: null,
    SSL_IMPLICIT: null,
    SSL_CHECK_CRL: null,
    SSL_CHECK_PERIOD_FOR_TRUSTED: null,
    SSL_IGNORE_CA_KEY_USAGE: null,
    SSL_IGNORE_SYSTEM_TRUST: null,
    SSL_MANDATORY_CLR_CHECK: null,
    SSL_MANDATORY_OCSP_CHECK: null,
    SSL_USE_SYSTEM_STORAGES: null,
    SSL_VALIDATE_INVALID_CERT: null,
    SSL_PRIVATE_KEY: null,
    SSL_KEY_PASSWORD: null,
    UPDATED_BY: 'Agustin.Polo@ingrambarge.com',
    UPDATED_ON: '2024-03-20T11:55:16.748Z',
    CREATED_BY: 'Agustin.Polo@ingrambarge.com',
    CREATED_ON: '2024-03-20T11:55:16.748Z',
    HOST_ACTIVE: 'A',
  },
];

export const profile: TowlineProfile = {
  USERNAME: 'APOLO@CLOUDX.COM',
  SEE_DEMURRAGE: 0,
  DESCRIPTION: null,
  USER_TYPE_TOWLINE: UserTypeEnum.CUSTOMER,
  DEFAULT_FILTER_ID: -1,
  DUP_BARGE_EMPTY_REMOVE_FLAG: 'N',
};

export const profiles: TowlineProfile[] = [
  {
    USERNAME: 'APOLO@CLOUDX.COM',
    SEE_DEMURRAGE: 0,
    DESCRIPTION: null,
    USER_TYPE_TOWLINE: UserTypeEnum.ADMIN,
    DEFAULT_FILTER_ID: -1,
    DUP_BARGE_EMPTY_REMOVE_FLAG: 'N',
  },
  {
    USERNAME: 'ARIEL.ERVITI@INGRAMBARGE.COM',
    SEE_DEMURRAGE: 0,
    DESCRIPTION: null,
    USER_TYPE_TOWLINE: UserTypeEnum.CUSTOMER,
    DEFAULT_FILTER_ID: -1,
    DUP_BARGE_EMPTY_REMOVE_FLAG: 'N',
  },
];

export const bargeProfile: BargeProfile = {
  BARGE_PROFILE_ID: 92015,
  USERNAME: 'APOLO@CLOUDX.COM',
  CUST_NUM: '20462',
  RIVER: null,
  MILEA: null,
  MILEB: null,
  DESCRIPTION: 'nothing',
  COMMODITY: null,
  BARGESTATUS: 'both',
  OTHER_RIVER: null,
  OTHER_MILEA: null,
  OTHER_MILEB: null,
};

export const secondaryBargeProfile: BargeProfile = {
  BARGE_PROFILE_ID: -11,
  USERNAME: 'APOLO@CLOUDX.COM',
  CUST_NUM: '20462',
  RIVER: null,
  MILEA: null,
  MILEB: null,
  DESCRIPTION: 'nothing',
  COMMODITY: null,
  BARGESTATUS: 'both',
  OTHER_RIVER: null,
  OTHER_MILEA: null,
  OTHER_MILEB: null,
};

export const bargeProfiles: BargeProfile[] = [
  bargeProfile,
  secondaryBargeProfile,
];

export const accessToken = 'mockAccessToken';
export const username = 'TEST@TEST.COM';
